<template>
  <div class="register">
    <section class="top">
      <div class="container">
        <a href="/#/login" title="立即登录"><i></i><p>立即登录</p></a>
        <div class="logo"><span></span><i></i></div>
      </div>
    </section>
    <section class="main">
      <div class="container">
        <div class="title"><span></span></div>

        <el-form  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  class="demo-ruleForm">
          <div class="box">
            <div class="boxname"><span>基本信息</span></div>
            <div class="boxinfo">
              <div class="row">
                <div class="typename">分销商类别<em>*</em></div>
                <div class="typelist">
                  <el-form-item class="top_radio">
                    <el-radio-group v-model="ruleForm.typeId">
                      <el-radio v-for="(item,index) in typeArr" :label="item.value" :value="item.value" :key="index">{{item.name}}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
              <div class="row two">
                <div class="item">
                  <div class="itemname">分销商名称<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="name">
                      <el-input v-model="ruleForm.name" placeholder="请输入分销商名称"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="itemname">分销商简称</div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="name">
                      <el-input v-model="ruleForm.abbreviation" placeholder="请输入分销商简称"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="item">
                  <div class="itemname">地址<em>*</em></div>
                  <div class="iteminfo">

                    <div class="region">
                      <el-form-item prop="selectedOptions" class="re_select">
                        <!--<el-select placeholder="请选择所在地区">
                          <el-option label="区域一" value="shanghai"></el-option>
                          <el-option label="区域二" value="beijing"></el-option>
                        </el-select>-->
                        <el-cascader
                          size="large"
                          :options="options"
                          v-model="ruleForm.selectedOptions"
                          @change="handleChange">
                        </el-cascader>
                      </el-form-item>
                    </div>
                    <div class="inputbox address">
                      <el-form-item prop="address">
                        <el-input v-model="ruleForm.address" placeholder="请输入分销商详细地址"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row two">
                <div class="item">
                  <div class="itemname">联系人<em>*</em></div>
                  <div class="iteminfo inputbox">

                    <el-form-item prop="contactName">
                      <el-input v-model="ruleForm.contactName" placeholder="请输入联系人姓名"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="itemname">手机号<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="phoneNumber">
                      <el-input v-model="ruleForm.phoneNumber" placeholder="请输入手机号" type="tel"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="row two">
                <div class="item">
                  <div class="itemname">座机<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="contactPhone">
                      <el-input v-model="ruleForm.contactPhone" placeholder="请输入分销商座机"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="itemname">电子邮箱<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="mailbox">
                      <el-input type="email" v-model="ruleForm.mailbox" placeholder="请输入电子邮箱"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="item">
                  <div class="itemname">备注</div>
                  <div class="iteminfo inputbox">
                    <el-form-item>
                      <el-input type="email" v-model="ruleForm.remark" placeholder="请输入备注"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="item">
                  <div class="itemname">公司简介</div>
                  <div class="iteminfo textarea">
                    <el-form-item>
                      <el-input class="proposal" type="textarea" v-model="ruleForm.synopsis" placeholder="请输入公司简介"></el-input>
                    </el-form-item>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="boxname"><span>财务联系人信息</span></div>
            <div class="boxinfo">
              <div class="row two">
                <div class="item">
                  <div class="itemname">财务人员<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="financeContact">
                      <el-input v-model="ruleForm.financeContact" placeholder="请输入财务人员姓名"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="itemname">手机号码<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="financePhone">
                      <el-input type="tel" v-model="ruleForm.financePhone" placeholder="请输入财务手机号码"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="row two">
                <!--<div class="item">
                  <div class="itemname">座机电话</div>
                  <div class="iteminfo inputbox">
                    <el-form-item>
                      <el-input v-model="ruleForm.name" placeholder="请输入座机号码"></el-input>
                    </el-form-item>
                  </div>
                </div>-->
                <div class="item">
                  <div class="itemname">电子邮箱</div>
                  <div class="iteminfo inputbox">
                    <el-form-item>
                      <el-input type="email" v-model="ruleForm.financeMailbox" placeholder="请输入电子邮箱"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="boxname"><span>分销商银行卡信息</span></div>
            <div class="boxinfo">
              <div class="row two">
                <div class="item">
                  <div class="itemname">开户行<em>*</em></div>
                  <div class="iteminfo inputbox">

                    <el-form-item prop="openingBank">
                      <el-input v-model="ruleForm.openingBank" placeholder="请输入开户行，如“中国XX银行XX省XX市XX支行”。"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="itemname">开户名<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="cardHolder">
                      <el-input v-model="ruleForm.cardHolder" placeholder="请输入开户名"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="row two">
                <div class="item">
                  <div class="itemname">银行卡号<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="bankNo">
                      <el-input v-model="ruleForm.bankNo" placeholder="请输入银行卡号"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="item">
                  <div class="itemname">预留手机<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="phone">
                      <el-input type="tel" v-model="ruleForm.phone" placeholder="请输入银行预留的手机号码"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="row two">
                <div class="item">
                  <div class="itemname">开户行名称<em>*</em></div>
                  <div class="iteminfo inputbox">
                    <el-form-item prop="bankName">
                      <el-input v-model="ruleForm.bankName" placeholder="请输入开户行名称"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="boxname"><span>资质信息</span></div>
            <div class="boxinfo">
              <div class="tips">请扫描上传您的营业执照、经营许可证等相关资质证书，并提供分销商加盖公章的委托书(<em>至少一张</em>)。</div>
              <div class="files"></div>
              <div class="add">
                <el-upload
                  action="#"
                  accept="image/jpeg,image/png,image/jpg"
                  list-type="picture-card"
                  ref="upload"
                  :before-upload="onBeforeUploadImage"
                  :on-change="fileChange"
                  :http-request="uploadImg"
                  :file-list="fileList">
                  <template #default>
                    <img src="../assets/images/re_upload.png" alt="">
                    <span>请上传您的资质文件</span>
                  </template>
                  <template #file="{file}">
                    <div>
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                      <span class="el-upload-list__item-actions">
                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </template>
                </el-upload>
                <!--<a href="javascript:addFile();" class="nofile" title="上传文件"><i></i><p>请上传您的资质文件</p></a>-->
                <!--<a href="javascript:addFile();" class="addmore" title="继续添加"><i></i><p>继续添加</p></a>-->
                <!--<input type="file" class="loadfile" accept="image/gif,image/jpeg,image/jpg,image/png,image/bmp" />-->
              </div>
            </div>
          </div>
        </el-form>
        <div class="btns">
          <a href="javascript:;" class="cancel" @click="goBack">退出</a><a @click="submit('ruleForm')" href="javascript:;" class="submit">提交</a>
        </div>
      </div>
    </section>

    <footerUl></footerUl>
  </div>
</template>

<script>
  import { regionData, CodeToText } from 'element-china-area-data'
  let checkPhone = (rule, value, callback) => {
    let reg = /^1[345789]\d{9}$/;
    if (!reg.test(value)) {
      callback(new Error('请输入正确的号码'))
    } else {
      callback()
    }
  }
  let checkPhoneMob = (rule, value, callback) => {
    let isMob = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
    if (!isMob.test(value)) {
      callback(new Error('请输入正确的号码'))
    } else {
      callback()
    }
  }
  let checkPhoneMail = (rule, value, callback) => {
    let isMob = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/;
    if (!isMob.test(value)) {
      callback(new Error('请输入正确的电子邮箱'))
    } else {
      callback()
    }
  }
  export default {
    name: "re",
    data() {
      return {
        fileList:[],
        options: regionData,
        ruleForm:{
          selectedOptions:[],//省市县
          address:'',//详细地址
          abbreviation:'',//详细地址
          bankName:'',//	银行名称
          bankNo:'',//银行卡号
          cardHolder:'',//开户名
          city:'',//市
          contactName:'',//联系人姓名
          contactPhone:'',//联系电话
          county:'',//区
          mailbox:'',//邮箱
          filePaths:[],//资质文件路径
          financeContact:'',//财务联系人
          financePhone:'',//财务手机号
          kindId:'2',//分销商分类Id*
          typeId:'2',//分销商分类Id*
          name:'',//分销商名称**
          openingBank:'',//开户行名称
          phone:'',//预留手机号码
          phoneNumber:'',//手机号
          province:'',//省
          remark:'',//
          synopsis:'',//
        },
        rules:{
          phone:[
            { required: true, message: '请输入银行预留手机号码', trigger: 'blur' },
            { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
          ],
          bankNo:[
            { required: true, message: '请输入银行卡号', trigger: 'blur' },
          ],
          bankName:[
            { required: true, message: '请输入开户行名称', trigger: 'blur' },
          ],
          cardHolder:[
            { required: true, message: '请输入开户名', trigger: 'blur' },
          ],
          openingBank:[
            { required: true, message: '请输入开户行名称', trigger: 'blur' },
          ],
          financePhone:[
            { required: true, message: '请输入财务人员手机号', trigger: 'blur' },
            { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
          ],
          financeContact:[
            { required: true, message: '请输入财务人员姓名', trigger: 'blur' },
          ],
          name:[
            { required: true, message: '请输入分销商名称', trigger: 'blur' },
          ],
          address:[
            { required: true, message: '请输入详细地址', trigger: 'blur' },
          ],
          contactName:[
            { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          ],
          contactPhone:[
            { required: true, message: '请输入分销商座机', trigger: 'blur' },
            { type: 'number', validator: checkPhoneMob, message: '请输入正确的座机号', trigger: ['blur', 'change'] }
          ],
          mailbox:[
            { required: true, message: '请输入电子邮箱', trigger: 'blur' },
            { validator: checkPhoneMail, message: '请输入正确的电子邮箱', trigger: ['blur', 'change'] }
          ],
          phoneNumber:[
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
          ],
          selectedOptions:[
            { required: true, message: '请选择地址', trigger: 'blur' },
          ],
        },
        typeArr:[],//分销商类别
      }
    },
    created() {
      this.getEnumsByCode();
    },
    methods: {
      handleChange (value) {
        this.ruleForm.province = CodeToText[this.ruleForm.selectedOptions[0]];
        this.ruleForm.city = CodeToText[this.ruleForm.selectedOptions[1]];
        this.ruleForm.county = CodeToText[this.ruleForm.selectedOptions[2]];
      },
      submit(formName){
        let _t = this;
        _t.$refs[formName].validate((valid) => {
          if (valid) {
            let send = JSON.parse(JSON.stringify(_t.ruleForm));
            delete send.selectedOptions;
            send.filePaths = [];
            _t.fileList.map(item => {
              send.filePaths.push(item.url)
            });
            _t.$api.registBusinessInfo(send).then(res => {
              _t.$router.push('/inAudit')
              // _t.$message.success('注册成功，等待管理员审核');
            })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      goBack(){
        this.$router.push('/login');
        this.$refs['ruleForm'].resetFields();
      },
      //获取枚举
      getEnumsByCode(){
        this.$api.getEnumsByCode('business_type').then(res => {
          this.typeArr = res.data;
        })
      },
      //图片处理
      handleRemove(file) {
        let num = null;
        this.fileList.map((item,index) => {
          if(item.url == file.url){
            num = index;
          }
        });
        this.fileList.splice(num,1)
      },
      onBeforeUploadImage(file) {
        const isIMAGE = file.type === 'image/jpeg' || 'image/jpg' || 'image/png'
        const isLt1M = file.size / 1024 / 1024 < 1
        if (!isIMAGE) {
          this.$message.error('上传文件只能是图片格式!')
        }
        if (!isLt1M) {
          this.$message.error('上传文件大小不能超过 1MB!')
        }
        return isIMAGE && isLt1M
      },
      uploadImg(param){
        const formData = new FormData()
        formData.append('file', param.file)
        this.$api.upload(formData).then(response => {
        }).catch(response => {
          let isPush = true;
          this.fileList.map(item => {
            if(item.url == response.data){
              isPush = false;
            }
          });
          if(isPush){
            this.fileList.push({name: '', url: response.data})
          }
          param.onSuccess()
        })
      },
      fileChange(file){
        this.$refs.upload.clearFiles() //清除文件对象
      },
    }
  }
</script>

<style scoped>
  @import "../assets/css/register.scss";

</style>
<style lang="scss">
  .re_select{
    .el-cascader{
      width: 100%;
      .el-input__inner{
        width: 100% !important;
      }
    }
  }
  .top_radio{
    .el-radio__inner{
      width: 25px;
      height: 25px;
    }
    .el-radio__inner::after{
      width: 12px;
      height: 12px;
    }
    .el-radio__label{
      font-size: 26px;
      font-weight: normal;
      color: #333;
    }
    margin-left: 60px;
    position: relative;
    top: -10px;
  }
  .register{
    .el-form-item{
      margin-bottom: 0;
    }
  }
</style>
